import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BaseService } from '../../../../core/services/base.service';
import { RiskRegisterDTO, RiskRegisterFormDTO } from '../dto/risk-register.dto';

@Injectable({
  providedIn: 'root',
})
export class RiskRegisterService extends BaseService<RiskRegisterDTO, RiskRegisterFormDTO> {
  protected apiUrl = environment.apiUrl + '/risk-register';

  constructor(http: HttpClient) {
    super(http);
  }

  getStatusTimeline(id:number) {
    return this.http.get<any>(`${environment.apiUrl}/risks/${id}/timelines?sort_by=id&order_by=asc`);
  }

  getControlPerformance(id:number) {
    return this.http.get<any>(`${environment.apiUrl}/risks/${id}/control-performance`);
  }
}
